.table-bordered {
  thead {
    th {
      border-top-width: 0;
      border-bottom-width: $table-border-width;
    }
  }
  tbody {
    td {
      border-style: solid;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: $table-accent-bg;
  }
}

.table-striped-secondary {
  tbody tr:nth-of-type(odd) {
    background-color: $table-secondary-accent-bg;
  }
}

