.cursor-pointer {
  cursor: pointer !important;
}

// Ensure this is added after any Bootstrap imports
.bg-upcoming {
  background-color: #d35400 !important; // Primary
  color: #fff !important;
}

.bg-in-progress {
  background-color: #2c3e50 !important; // Info
  color: #fff !important;
}

.bg-completed {
  background-color: #28a745 !important; // Success
  color: #fff !important;
}

.pill {
  border-radius: 25px;
  padding: 0.25rem 0.75rem;
  display: inline-block;
  font-size: 0.65rem;
  padding: 0.5rem 0.75rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
  cursor: default;
}

.pill:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.bg-cancelled {
  background-color: #dc3545 !important; // Danger
  color: #fff !important;
}

.bg-workers-in-transit {
  background-color: #8e44ad !important; // Warning
  color: #fff !important;
}

.bg-unknown {
  background-color: #6c757d !important; // Secondary
  color: #fff !important;
}

.text-link {
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.button-bold {
  font-weight: bold;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 80vh;
  max-width: 100%;
  margin-bottom: 1.5rem;
}

.badging-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: scale-down;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button-container {
  width: 100%;
  display: flex;
}
