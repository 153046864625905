.picker-wrapper {
    display: flex;
    justify-content: center;
}

.react-datepicker__day--keyboard-selected.react-datepicker__day--highlighted {
    background-color: #1dac2a;
}

.react-datepicker__day--today {
    background-color: white;
}

.date-picker-example {
    margin-left: 80px;
    opacity:0%;
}

.date-picker-caption {
    margin-left: 80px;
}

.show-picker-button {
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    padding-top: 1.0rem;
    padding-bottom: 1.0rem;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.125rem;
    border-radius: 0.35rem;
    color: #fff;
    background-color: #053f5e;
    border-color: #053f5e;
    hover-color: #fff;
    hover-bg: #043650;
    hover-border-color: #04324b;
    focus-shadow-rgb: 43, 92, 118;
    active-color: #fff;
    active-bg: #04324b;
    active-border-color: #042f47;
    active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    disabled-color: #fff;
    disabled-bg: #053f5e;
    disabled-border-color: #053f5e;
}