@import "bootstrap-custom";
@import "buorg";
@import "global";
@import "multiDatePicker";

.calendar-month-header {
  padding-left: 5px;
  margin-bottom: 5px;
}
.calendar {
  display: flex;
  padding-bottom: 1%;
  gap: 2px;

  flex-wrap: wrap;
  font-family: "Poppins";

  .day {
    display: flex;
    flex-direction: column;
    border: thin solid #053f5e !important;
    min-height: 100px;
    width: 19%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .day-header {
      width: 100%;
      padding: 5px;

      font-size: 14px;
      background-color: #053f5e;
      color: white;
      font-weight: bold;

      @media screen and (max-width: 768px) {
        display: inline-block;
        width: 40%;
      }
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      min-height: auto;
      flex-direction: row;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.orientation {
  display: grid;

  video {
    width: 100%;
    grid-area: 1/1;
    align-self: center;
  }
  .orientationMessage {
    grid-area: 1/1;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 3%;
    animation: fadeIn 0.4s;
    .orientationMessageWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      h1 {
        color: white !important;
        text-align: center;
      }
    }
  }
  .orientationQuestion {
    grid-area: 1/1;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 3%;
    animation: fadeIn 0.4s;

    .questionText {
      color: white;
      padding-bottom: 1%;
      border-bottom: thin solid white;
    }
    .questionChoices {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: white;
      animation: fadeIn 0.4s;

      .questionChoice {
        width: 100%;
        font-family: "Poppins";
        display: flex;
        gap: 10px;
        color: white;
        cursor: pointer;
        padding: 1%;

        &:hover {
          color: black;
          background: white;
        }
      }
    }
    .questionFeedback {
      animation: fadeIn 0.4s;
      font-size: 30px;
      font-family: "Poppins";
      margin-top: 24px;

      &.right {
        color: green;
      }
      &.wrong {
        color: red;
      }
    }
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;

    background-color: black;
    z-index: 9999;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
