// Global App SCSS
/* improted for mixin */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

body {
  overflow-x: hidden;
}

html,
body {
  height: 100%;
  min-height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#__next {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.container-fluid {
  max-width: 1210px;
  @include media-breakpoint-down(lg) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

main {
  width: 100%;
}

.form-container {
  @include media-breakpoint-up(md) {
    max-width: 640px;
    margin: 0 auto;
  }
}

hr.dashed {
  border-style: dashed;
}

h1.large {
  font-size: 32px;
  @include media-breakpoint-up(md) {
    font-size: 44px;
  }
}

h2 > a {
  text-decoration: underline !important;
  text-decoration-color: (237, 110, 35) !important;
}

.marginLite {
  margin: 2%;
  margin-top: 2%;
}
.marginless {
  margin: 0%;
  margin-top: 2%;
}
@media only screen and (max-width: 750px) {
  .alert-danger {
    display: none !important;
  }
}

.parent {
  text-align: center;
}

.bolder {
  font-weight: bolder;
}

.card {
  border: none;
  border-radius: 0.25rem;
}

.upcoming-shifts {
  padding: 2rem0;
}

.upcoming-shiftsh2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.shift-card {
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #e3e6e8;
  background-color: #ffffff;
  margin-bottom: 1.5rem;
  transition:
    box-shadow 0.3s ease,
    transform 0.3s ease;
}

.shift-card:hover {
  box-shadow: 04px10pxrgba (0, 0, 0, 0.1);
  transform: translateY(-3px);
}

.shift-carda {
  color: #ed6e23;
  font-weight: bold;
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.shift-carda:hover {
  color: #e54a1d;
}

.shift-card.shift-details {
  font-size: 0.9rem;
  color: #6c757d;
}

.shift-card.workers-count {
  margin-top: 0.5rem;
  color: #5e6a76;
  font-weight: 600;
}

@media (min-width: 768px) {
  .shift-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}

.moveLeft {
  padding-right: 260px !important;
  margin-right: 35px !important;

  @include media-breakpoint-up(sm) {
    padding-right: 0px !important;
    padding: 0px;
    margin: 0px;
  }

  @include media-breakpoint-up(md) {
    padding-right: 0px;
    padding: 0px;
    margin: 0px;
  }
}

.ParentContainer {
  display: flex;
  margin-left: 0px;
  padding: 0px;
}

.Child {
  @media (min-width: 750px) {
    padding-right: 0px !important;
    margin-right: 0px !important;
    padding-right: clamp(20em, 20em, 20em) !important;
    margin-right: clamp(2px, 2px, 2px) !important;
  }
  @media (min-width: 733px) {
    padding-right: 0px !important;
    margin-right: 0px !important;
    .Grandparent {
      display: flex-box !important;
      align-content: center;
    }
    padding-right: clamp(20em, 20em, 20em) !important;
    margin-right: clamp(2px, 2px, 2px) !important;
  }
  @media (min-width: 577px) {
    padding-right: 0px !important;
    margin-right: 0px !important;
    padding-right: clamp(10em, 15em, 20em) !important;
    margin-right: clamp(2px, 4px, 5px) !important;
  }
  @media (min-width: 360px) {
    display: flex !important;
    margin-right: 0px !important;
    padding-right: clamp(0em, 0em, 0em) !important;
    margin-right: clamp(0px, 0px, 0px) !important;

    .display-overwrite {
      display: flexbox !important;
      align-content: center;
      align-items: float-left !important;
    }
  }
  @media (max-width: 320px) {
    display: flex !important;
    margin-right: 0px !important;
    #foreman-name {
      display: shrink !important;
      width: 80% !important;
      overflow: hidden;
      padding-left: 6px !important;
      flex-shrink: 3;
      font-weight: normal !important;
    }
    padding-right: clamp(0em, 0em, 0em) !important;
    margin-right: clamp(0px, 0px, 0px) !important;
    .display-overwrite {
      display: clamp(flexbox, flexbox, flexbox) !important;
      display: flexbox !important;
      display: flex-box !important;
    }
    .Grandparent {
      display: clamp(flexbox, flexbox, flexbox) !important;
      display: flexbox !important;
    }
    .Child {
      display: flexbox !important;
      display: clamp(flexbox, flexbox, flexbox) !important;
      display: flexbox !important;
    }
    align-content: center;
    align-items: center !important;
  }

  @media (min-width: 576px) {
    padding-right: 0px !important;
    margin-right: 0px !important;
    padding-right: clamp(0em, 0em, 0em) !important;
    margin-right: clamp(0px, 0px, 0px) !important;
  }

  padding-right: clamp(40em, 40em, 40em) !important;
  margin-right: clamp(25px, 25px, 25px) !important;
}

.innerchild {
  @media (max-width: 700px) {
    width: clamp(5em, 10em, 12em);
    padding-right: 0px !important;
    margin-right: 0px !important;
    padding: 0px;
    margin: 0px;
  }
  @media (max-width: 500px) {
    padding-right: 0px !important;
    margin-right: 0px !important;
    padding: 0px;
    margin: 0px;
    .flag {
      margin: clamp(0px, 0px, 0px) !important;
      padding-left: clamp(30px, 30px, 30px) !important;
      float: right !important;
    }
  }
  @media (max-width: 360px) {
    padding-right: 0px !important;
    margin-right: 0px !important;
    padding: 0px;
    margin: 0px;
  }
  width: clamp(15em, 15em, 15em);
}

.Button {
  margin-right: clamp(6em, 6em, 6em) !important;
  color: white !important;
}

.btn-full-width {
  background-color: #f36c21 !important;
  color: white !important;
  border: none;
}

.is-invalid {
  border-color: red;
}

.text-danger {
  color: red;
}

text-waitlisted {
  color: #f36c21;
}

#sentry-feedback {
  --inset: auto auto 0 0;
  --page-margin: 16px;
}

.react-select-container {
  border: none;
}

.react-select__control {
  border-color: #ced4da;
}

.is-invalid .react-select__control {
  border-color: red;
}

.text-danger {
  color: red !important;
}

.uaTrack-btn-forgot-password {
  color: white !important;
}

.react-select-error .react-select__control {
  border-color: red !important;
  box-shadow: 0 0 0 1px red !important;
}

.react-select-error .react-select__control:hover {
  border-color: red !important;
}

.display-overwrite {
  @media (max-width: 320px) {
    display: flexbox !important;
    flex-direction: column !important;
    justify-content: center;
  }
}

/* Header Styles */
.header {
  position: relative;
  width: 100%;
  display: flex;
  font-size: 14px;
  font-family: $headings-font-family;
  border-bottom: 1px solid $border-alt;
}

/* Navbar Brand */
.navbarBrand {
  line-height: 1;
}

/* Logo SVG */
.logoSvg {
  width: 176px;
  height: 39px;
}

/* Navigation Items */
.navItem {
  margin-left: 15px;
}

.navLink {
  padding: 12px 15px;
  font-weight: bold;
  color: $dark;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $primary;
    text-decoration: none;
  }
}

.activeNavLink {
  color: $primary;
  border-bottom: 2px solid $primary;
}

header {
  position: relative;
  width: 100%;
  display: flex;
  font-size: 14px;
  font-family: $headings-font-family;
}

/* Remove top and bottom margins */
.no-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* Optional: Remove margins only on mobile devices */
@media (max-width: 576px) {
  .no-margin-mobile {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

:global(header.border-bottom) {
  border-color: $border-alt !important;
}
header :global(.navbar-brand) {
  line-height: 1;
}
header :global(.logo svg) {
  width: 176px;
  height: 39px;
}

@include media-breakpoint-up(md) {
  header :global(.nav-item) {
    margin-left: 15px;
  }
  header :global(.nav-item a.nav-link) {
    padding: 12px 15px;
    font-weight: bold;
    color: $dark;
  }
  header :global(.nav-item a.nav-link:hover),
  header :global(.nav-item a.nav-link:focus) {
    color: $primary;
  }
}
header :global(.bm-burger-button) {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0px;
  top: 10px;
  z-index: 55000;
  line-height: 1;
}
header :global(.bm-menu-wrap) {
  height: 100%;
  background: #fff;
  top: 0;
}
header :global(.bm-menu) {
  background: #fff;
  padding: 0;
  color: $body-color;
}
header :global(.bm-burger-bars) {
  background: $primary;
  border-radius: 2px;
  height: 3px !important;
}
header :global(.bm-item-list) {
  color: $secondary;
  padding: 0;
}
header :global(.bm-item a) {
  color: $body-color;
}
header :global(a.bm-item),
header :global(.bm-item),
header :global(.bm-item.nav-link) {
  display: block;
  margin: 0;
  text-align: left;
  padding: 1.5rem;
  font-size: 16px;
  border-bottom: 1px solid $border-alt;
  color: $body-color;
  letter-spacing: normal;
  font-weight: bold;
}
header :global(.bm-item a:hover),
header :global(.bm-item a:focus) {
  text-decoration: none;
  outline: none;
}
header :global(.bm-overlay) {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4) !important;
  opacity: 1;
  left: 0;
  top: 0;
  transition: opacity 0.3s;
}
header :global(.bm-cross-button) {
  height: 24px;
  width: 24px;
}
:global(.mobile-menu-open .bm-cross-button) {
  left: -35px;
}
header :global(.bm-cross) {
  background: #fff;
}

.container {
  width: 100%;
  max-width: 1200px;
}

/* Mobile styles */
.wizard-container {
  width: 100vw !important;
  max-width: 100% !important;
}

@media (min-width: 768px) {
  .form-container {
    margin: 0 auto;
    max-width: 640px;
  }
}

.relative-position {
  position: relative;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-form-container input,
.sign-in-form-container button {
  width: 100% !important;
}

/* Mobile styles */
.wizard-container .form-container {
  max-width: 100% !important;
}

/* Desktop styles */
@media (min-width: 768px) {
  .wizard-container .form-container {
    max-width: 90vw !important;
  }
}

.react-time-picker__wrapper {
  border: 1px solid #aab7c1;
  border-radius: 0.25rem;
  padding: 1.09rem;
  font-size: 1rem;
  background-color: #fff;
  background-clip: padding-box;
}

.react-time-picker__wrapper:hover {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.react-time-picker__inputGroup {
  font-size: 1.25rem;
  color: #5e6a76;
  font-family:
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
}

.react-time-picker__inputGroup__leadingZero {
  display: none;
}

.react-time-picker__inputGroup__input,
.react-time-picker__inputGroup__hour {
  font-size: 1.25rem;
  width: 1rem !important; /* Adjust as needed */
  border: none !important; /* Remove border */
  color: #5e6a76;
  font-family:
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
}

.react-time-picker__inputGroup__minute {
  font-size: 1.25rem;
  width: 1.8rem !important; /* Adjust as needed */
  border: none !important; /* Remove border */
  color: #5e6a76;
  font-family:
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
}

.react-time-picker__clear-button,
.react-time-picker__button {
  display: none;
}

.no-padding-left {
  padding-left: 0px !important;
}

.react-time-picker__button__icon {
  display: none; /* Hides the stepper buttons */
}

.react-time-picker__inputGroup__input.react-time-picker__inputGroup__amPm {
  font-size: 0.75rem; /* Adjust as needed */
  height: 1.5rem; /* Adjust as needed */
  width: 2.5rem !important;
  margin-left: 5px;
  background-color: #fff;
  color: #5e6a76;
  font-family:
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
}

.react-time-picker__inputGroup__leadingZero {
  font-size: 1rem; /* Adjust as needed */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.autocomplete-input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(/static/images/select-arrow.svg);
  background-position:
    right 1.5em top 52%,
    0 0;
  background-repeat: no-repeat;
  background-size:
    0.65em auto,
    100%;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #aab7c1;
  border-radius: 0.25rem;
  color: #5e6a76;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: calc(1.5em + 2.7rem + 2px);
  line-height: 1.5;
  padding: 1.35rem 1.5rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .autocomplete-input {
    transition: none;
  }
}
