/*
 * Style customization for Browser Update
 * See https://browser-update.org/customize.html
 */

@import "styled-jsx-helper";

body {
  .buorg {
    border-bottom: none;
    color: $white;
    background-color: $secondary;
    font-family: $font-family-base;
  }

  #buorgig, #buorgul, #buorgpermanent {
    @extend .btn;
    @extend .btn-sm;
    box-shadow: none;
  }

  #buorgul {
    @include button-variant($green, $green);
  }

  #buorgig, #buorgpermanent {
    @include button-variant($danger, $danger);
  }
}
